import { Link, usePage } from "@inertiajs/react";

export default function NavigationLink({ linkItem, children, ...props }) {
  const { user } = usePage().props;

  const linkComponent = () => {
    if (linkItem.inertiaLink) {
      return (
        <Link href={linkItem.href} data-turbo={false} {...props}>
          {children}
        </Link>
      );
    } else {
      return (
        <a href={linkItem.href} data-turbo={false} {...props}>
          {children}
        </a>
      );
    }
  };

  if (linkItem.orgAdmin) {
    return user.org_admin ? linkComponent() : <></>;
  } else if (linkItem.appAdmin) {
    return user.app_admin ? linkComponent() : <></>;
  } else {
    return linkComponent();
  }
}
