import NavigationLink from "@/components/NavigationLink";

export default function DesktopSidebar({ navigation }) {
  return (
    <nav className="hidden w-44 flex-col flex-wrap space-y-4 md:flex">
      {navigation.map((item, idx) => (
        <NavigationLink
          key={idx}
          linkItem={item}
          className="text-amp-medium-gray group flex items-center rounded-sm px-2 py-1 hover:bg-gray-50"
        >
          <div className="mr-2.5">
            <item.icon />
          </div>
          <span className="truncate">{item.name}</span>
        </NavigationLink>
      ))}
    </nav>
  );
}
