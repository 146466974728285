import HomeIcon from "@/icons/HomeIcon";
import BoltIcon from "@/icons/BoltIcon";
import TargetIcon from "@/icons/TargetIcon";
import ClipboardDocumentListIcon from "@/icons/ClipboardDocumentListIcon";
import DesktopSidebar from "./DesktopSidebar";

const navigation = [
  { name: "Dashboard", href: "/", icon: HomeIcon, inertiaLink: false },
  { name: "Suits", href: "/suits", icon: BoltIcon, inertiaLink: true },
  {
    name: "Malfunction reports",
    href: "/malfunction_reports",
    icon: ClipboardDocumentListIcon,
    inertiaLink: true,
    appAdmin: false,
  },
  {
    name: "Admin",
    href: "/admin",
    icon: TargetIcon,
    inertiaLink: false,
    orgAdmin: true,
  },
];

const Layout = ({ children }) => {
  return (
    <div className="flex justify-between">
      <DesktopSidebar navigation={navigation} />
      {children}
    </div>
  );
};

export default (page) => <Layout>{page}</Layout>;
